<template>
    <v-container>
        <v-row dense id="dash_page">
            <v-col cols="12">
                <v-form v-model="valid">
                    <v-jsf v-model="model" :schema="schema"/>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import VJsf from '@koumoul/vjsf/lib/VJsf.js';
    import '@koumoul/vjsf/lib/VJsf.css';
    import '@koumoul/vjsf/lib/deps/third-party.js';

    export default {
        name: 'App',
        components: {VJsf},
        data: () => ({
            valid: false,
            model: {},
            schema: {}
        }),
        async mounted() {
            let response = await this.$store.dispatch('apiRequest', {
                method: 'get',
                url: 'user_actions_json',
                params: {
                    user_id: 12
                }
            });
            this.schema = JSON.parse(response);
        }
    };
</script>


<!--<script>-->
<!--import FormSchema from '@formschema/native';-->
<!--//    import schema from '../assets/user_actions.json';-->

<!--//    export default {-->
<!--//        data: () => ({-->
<!--//            schema: schema,-->
<!--//            model: {}-->
<!--//        }),-->
<!--//        methods: {-->
<!--//            submit (e) {-->
<!--//                // this.model contains the valid data according your JSON Schema.-->
<!--//                // You can submit your model to the server here-->
<!--//            }-->
<!--//        },-->
<!--//        components: { FormSchema }-->
<!--//    };-->
<!--export default {-->
<!--components: {FormSchema},-->
<!--data: () => ({-->
<!--schema: {}-->
<!--}),-->
<!--methods: {-->
<!--},-->
<!--async mounted() {-->
<!--let response = await this.$store.dispatch('apiRequest', {-->
<!--method: 'get',-->
<!--url: 'user_actions_json'-->
<!--});-->
<!--console.log(response);-->
<!--this.schema = JSON.parse(response);-->
<!--}-->
<!--}-->
<!--</script>-->